import SlimSelect from "slim-select"

const App = {
	industry: null,
	selected: [],
	run: function(block) {
		this.initSelector(block)
		this.initShowAll(block)
		this.almChangePostsPerPage(block)
	},
	initSelector(block) {
    const industry = block.querySelector('[name="industry"]')

    this.industry = new SlimSelect({
      select: industry,
      allowDeselectOption: true,
      onChange: info => this.filterQuery(info, industry, block)
    })
  },

	filterQuery(info = null, industry = null, block) {
    let selected = [];

    if ( industry.value !== '' ) {
      selected.push(industry.value)
    }

    let data = { 'taxonomy': 'category', 'taxonomy-terms': `${selected}`, 'taxonomy-operator': 'IN', 'taxonomy-relation': 'OR', 'target': block.id }

    if (info.selected) {
      ajaxloadmore.filter( this.transition, this.speed, data )
    }
  },

	initShowAll(block) {
    const showAllBtn = block.querySelector('.show-all')

    showAllBtn.addEventListener('click', event => {
      event.preventDefault()

      this.industry.set()

      let data = { 'taxonomy': '', 'taxonomy-terms': '', 'taxonomy-operator': '', 'taxonomy-relation': '', 'target': block.id }

      ajaxloadmore.filter( this.transition, this.speed, data )
    })
  },

	almChangePostsPerPage(block) {
    window.almOnChange = function(alm){
			console.log(alm)

      // if (alm.page >= 1 ) {
			// 	alm.posts_per_page = 6
			// }
    }
  },

	initializeBlock: function(block) {
		// let wrapper = block.querySelector('.works-wrapper')
		this.run(block)
	},

	init() {
		// Get all card block
		const blocks = document.querySelectorAll('.elmntl-client')
		blocks.forEach(block => {
			this.initializeBlock(block)
		})
	}
}

window.addEventListener('DOMContentLoaded', () => App.init())